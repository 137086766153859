import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ActionButton from "../components/actionButton"

const GoogleBewerten = ({data}) => {

  return (
    <Layout title="Google Bewertung" slogan="Wir danken Ihnen für Ihre Bewertung!" headerImage={data.image1.childImageSharp.fluid}> 
        <div>
          <p>
            Bewerten Sie uns auf Google. Wir sind dankbar um Ihre Meinung. <br/>
            Klicken Sie zum Bewerten auf den folgenden Link und füllen das Formular aus:<br/>
            <br/>
            <a href="https://g.page/r/CR60u8zVMvXIEAg/review" target="_blank" rel="noopener noreferrer">Altersheim Bannau auf Google bewerten</a>
            <br/><br/>
            Vielen Dank,<br/>
            Ihr Bannau Team
          </p>
        </div>
    </Layout>
  ) 
}

export default GoogleBewerten

export const pageQuery = graphql`
  query {
    image1: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-start-eingang.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`